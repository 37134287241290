import React, { useState, useEffect, useContext } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { LogoutOutlined, MenuOutlined } from '@ant-design/icons';
import { withSnackbar } from 'notistack';
import { SafeArea } from 'antd-mobile'

const Header = (props) => {
  const { classes, openMenu } = props;

  return (
    <div className={classes.mobile_header}>
      <MenuOutlined onClick={openMenu} className={classes.menu}/>
    </div>
  );
};

const styles = (theme) => ({
  mobile_header: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
    marginTop: '20px',
  },
  menu: {
    fontSize: "30px",
    color: '#59059B',
    marginRight: "27px",
  },
});

const mapStateToProps = (state) => {
  return { user: state.user };
};

export default connect(
  mapStateToProps,
  null,
)(withStyles(styles)(withSnackbar(Header)));
